var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"pageContainer step3PageContainer"},[_c('StepsIndicator'),_c('div',{staticClass:"pageContent"},[_c('div',{staticClass:"STLiBian pageTitles"},[_vm._v("请选择提货方式")]),_c('div',{staticClass:"tabsBtns2"},[_c('div',{class:{
          tabBtn: true
        },on:{"click":function($event){return _vm.changeTab('express')}}},[_c('img',{directives:[{name:"show",rawName:"v-show",value:(_vm.deliverType === 'express'),expression:"deliverType === 'express'"}],attrs:{"src":require("./images/mfps1.png"),"alt":""}}),_c('img',{directives:[{name:"show",rawName:"v-show",value:(_vm.deliverType !== 'express'),expression:"deliverType !== 'express'"}],attrs:{"src":require("./images/mfps2.png"),"alt":""}})]),_c('div',{class:{
          tabBtn: true
        },on:{"click":function($event){return _vm.changeTab('self')}}},[_c('img',{directives:[{name:"show",rawName:"v-show",value:(_vm.deliverType === 'self'),expression:"deliverType === 'self'"}],attrs:{"src":require("./images/ddzq1.png"),"alt":""}}),_c('img',{directives:[{name:"show",rawName:"v-show",value:(_vm.deliverType !== 'self'),expression:"deliverType !== 'self'"}],attrs:{"src":require("./images/ddzq2.png"),"alt":""}})])]),_c('div',{staticClass:"STLiBian tx1"},[_vm._v("完善持卡人信息")]),(_vm.manualInputAddress)?[_c('div',{staticClass:"STLiBian inputTips0"},[_vm._v(" 请务必填写真实信息以绑定此卡！ ")]),_c('div',{class:{
          inputArea: true,
          styleSelf: _vm.deliverType === 'self'
        }},[_c('input',{attrs:{"placeholder":'请填写您的真实姓名'},domProps:{"value":_vm.name},on:{"input":_vm.changeInputName,"blur":_vm.inputBlur}}),(_vm.validateName)?_c('div',{staticClass:"ok"}):_vm._e()]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.deliverType !== 'self'),expression:"deliverType !== 'self'"}],staticClass:"STLiBian inputTips"}),_c('div',{class:{
          inputArea: true,
          styleSelf: _vm.deliverType === 'self'
        }},[_c('input',{attrs:{"placeholder":'请填写您常用的手机号码'},domProps:{"value":_vm.mobile},on:{"input":_vm.changeInputMobile,"blur":_vm.inputBlur}}),(_vm.validateMobile)?_c('div',{staticClass:"ok"}):_vm._e()]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.deliverType !== 'self'),expression:"deliverType !== 'self'"}],staticClass:"STLiBian inputTips"}),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.deliverType === 'express'),expression:"deliverType === 'express'"}],staticClass:"tab"},[_c('div',{staticClass:"inputArea"},[_c('input',{attrs:{"placeholder":"请详细填写收件人地址"},domProps:{"value":_vm.address},on:{"input":_vm.changeInputAddress,"blur":_vm.inputBlur}}),(_vm.validateAddress)?_c('div',{staticClass:"ok"}):_vm._e()]),_c('div',{staticClass:"STLiBian inputTips"})])]:[(!_vm.wxReady || _vm.deliverType === 'self')?[_c('div',{staticClass:"STLiBian inputTips0"},[_vm._v(" 请务必填写真实信息以绑定此卡！ ")]),_c('div',{class:{
            inputArea: true,
            styleSelf: _vm.deliverType === 'self'
          }},[_c('input',{attrs:{"placeholder":'请填写您的真实姓名'},domProps:{"value":_vm.name},on:{"input":_vm.changeInputName,"blur":_vm.inputBlur}}),(_vm.validateName)?_c('div',{staticClass:"ok"}):_vm._e()]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.deliverType !== 'self'),expression:"deliverType !== 'self'"}],staticClass:"STLiBian inputTips"}),_c('div',{class:{
            inputArea: true,
            styleSelf: _vm.deliverType === 'self'
          }},[_c('input',{attrs:{"placeholder":'请填写您常用的手机号码'},domProps:{"value":_vm.mobile},on:{"input":_vm.changeInputMobile,"blur":_vm.inputBlur}}),(_vm.validateMobile)?_c('div',{staticClass:"ok"}):_vm._e()]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.deliverType !== 'self'),expression:"deliverType !== 'self'"}],staticClass:"STLiBian inputTips"})]:_vm._e(),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.deliverType === 'express'),expression:"deliverType === 'express'"}],staticClass:"tab"},[(!_vm.wxReady)?_c('div',{staticClass:"inputArea"},[_c('input',{attrs:{"placeholder":"请详细填写收件人地址"},domProps:{"value":_vm.address},on:{"input":_vm.changeInputAddress,"blur":_vm.inputBlur}}),(_vm.validateAddress)?_c('div',{staticClass:"ok"}):_vm._e()]):[_c('div',{staticClass:"shurudizhi",on:{"click":_vm.getWxAddressHander}},[_c('img',{attrs:{"src":require('./images/shurudizi.png'),"alt":""}})]),_c('div',{staticClass:"STLiBian inputTips padding-bottom-30"},[_vm._v(" 请务必填写真实信息以绑定此卡！ ")])],_c('div',{staticClass:"STLiBian inputTips"})],2)],_c('div',{class:{
        inputFileArea: true,
        styleSelf: _vm.deliverType === 'self'
      }},[_c('label',{staticClass:"btnPhoto",attrs:{"for":"photoFile"}},[_c('img',{attrs:{"src":require('./images/pz.png'),"alt":""}})]),_c('input',{attrs:{"id":"photoFile","type":"file","accept":"image/*","capture":""},on:{"change":_vm.onClickTakePhoto}}),_c('div',{staticClass:"STLiBian inputTips"},[_vm._v(_vm._s(_vm.fileKey ? '已上传' : '请拍摄“挚情卡”正面照片'))])])],2),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.deliverType === 'self'),expression:"deliverType === 'self'"}],staticClass:"STLiBian txt3"},[_vm._v(" 重要提示：来店自提，请务必携带此卡！ ")]),_c('div',{staticClass:"btns btns_row"},[_c('div',{staticClass:"btn isValidated",on:{"click":_vm.chageStepIndexTpPerv}},[_vm._v("上一步")]),_c('div',{class:{
        btn: true,
        isValidated: _vm.validateNextBtn
      },on:{"click":_vm.chageStepIndexToNext}},[_vm._v(" "+_vm._s(_vm.nextBtnStr)+" ")])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }