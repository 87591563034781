var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"pageContainer"},[_c('StepsIndicator'),_c('div',{staticClass:"pageContent"},[_c('div',{staticClass:"STLiBian tx1"},[_vm._v(" 本卡内含： "),(_vm.cardInfo.giftPackage.isShowAmount)?[_vm._v("价值 "),_c('span',{staticClass:"AvantGardeITCbyBT amount"},[_vm._v(_vm._s(_vm.cardInfo.giftPackage.amount))]),_vm._v("元礼箱 ")]:_vm._e()],2),_c('div',{staticClass:"presentsOuter"},[_c('div',{staticClass:"presents"},_vm._l((_vm.presents),function(item,index){return _c('div',{key:item + index,staticClass:"item"},[_c('div',{staticClass:"title"},[_vm._v(_vm._s(item.title))]),_c('div',{staticClass:"dot"}),_c('div',{staticClass:"unit"},[_vm._v(_vm._s(item.unit))])])}),0)])]),_c('div',{class:{
      btns: true,
      btns_row: true
    }},[(_vm.isActivated)?[_c('div',{class:{
          btn: true,
          isValidated: true
        },on:{"click":_vm.chageStepIndexTpPerv}},[_vm._v("返回")])]:[_c('div',{class:{
          btn: true,
          isValidated: true
        },on:{"click":_vm.chageStepIndexToNext}},[_vm._v(" 马上激活 ")]),_c('div',{class:{
          btn: true,
          isValidated: true
        },on:{"click":_vm.chageStepIndexTpPerv}},[_vm._v(" 以后再说 ")])]],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }