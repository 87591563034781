var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"pageContainer"},[_c('StepsIndicator'),(_vm.cardInfo.getPresentWay === 'EXPRESS')?_c('div',{staticClass:"pageContent"},[(!_vm.cardInfo.express)?_c('div',{staticClass:"presentsOuter presentsOuterA STLiBian"},[_c('div',{staticClass:"t1a"},[_vm._v(" 未发货，请稍等！ ")]),_c('div',{staticClass:"t2a"},[_vm._v(" 发货说明 ")]),_vm._m(0)]):_vm._e(),(_vm.cardInfo.express)?_c('div',{staticClass:"presentsOuter STLiBian"},[_c('div',[_vm._v("您的礼箱已经"+_vm._s(_vm.cardInfo.express.companyName === '跑腿配送' ? '提' : '发')+"货")]),_c('div',[_vm._v("提货方式："+_vm._s(_vm.cardInfo.express.companyName))]),_c('div',[_vm._v("很荣幸为您提供服务")]),(_vm.cardInfo.express.companyName !== '跑腿配送')?[_c('div',{staticClass:"t1"},[_vm._v(" 冷链物流单号 ")]),_c('div',{staticClass:"AvantGardeITCbyBT t2"},[_vm._v(" "+_vm._s(_vm.cardInfo.express.expressNo)+" ")]),_c('div',{staticClass:"t3"},[_vm._v("本次配送单位："+_vm._s(_vm.cardInfo.express.companyName)),_c('br'),_vm._v("每晚22点前更新物流信息")]),_c('div',{staticClass:"t4",on:{"click":_vm.copyHandler}},[_vm._v(" 点我复制单号 ")]),_vm._m(1),_c('div',{staticClass:"t4",on:{"click":_vm.gotoWeb}},[_vm._v(" 进入物流官网 ")]),_c('div',{staticClass:"t4",on:{"click":_vm.callPhone}},[_vm._v(" 拨打物流客服电话 ")])]:void 0],2):_vm._e()]):_vm._e(),_c('div',{class:{
      btns: true,
      btns_row: true
    }},[_c('div',{class:{
        btn: true,
        isValidated: true
      },on:{"click":_vm.chageStepIndexTpPerv}},[_vm._v(" 返回 ")])])],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"t3a"},[_vm._v(" 本店收到配送要求后，24小时内发货"),_c('br'),_vm._v(" 发货后可在此查询配送单号 ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"t5"},[_vm._v("详细配送信息可在"),_c('br'),_vm._v("物流官方网站进行查询")])
}]

export { render, staticRenderFns }