var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"pageContainer"},[_c('StepsIndicator'),(_vm.cardInfo.getPresentWay === 'EXPRESS')?_c('div',{staticClass:"pageContent"},[_vm._m(0)]):_vm._e(),_c('div',{class:{
      btns: true,
      btns_row: true
    }},[_c('div',{class:{
        btn: true,
        isValidated: true
      },on:{"click":_vm.chageStepIndexTpPerv}},[_vm._v(" 返回 ")]),_c('div',{class:{
        btn: true,
        isValidated: true
      },on:{"click":_vm.clickCheckCardPresents}},[_vm._v(" 卡内详情 ")])])],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"presentsOuter presentsOuterA STLiBian"},[_c('div',{staticClass:"t1a"},[_vm._v(" 未发货，请稍等！ ")]),_c('div',{staticClass:"t2a"},[_vm._v(" 发货说明 ")]),_c('div',{staticClass:"t3a"},[_vm._v(" 本店收到配送要求后，24小时内发货"),_c('br'),_vm._v(" 发货后可在此查询配送单号 ")])])
}]

export { render, staticRenderFns }