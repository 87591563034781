var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"pageContainer"},[_c('StepsIndicator'),_vm._m(0),_c('div',{class:{
    btns:true, btns_row:true
  }},[_c('div',{class:{
    btn: true,
    isValidated: true
  },on:{"click":_vm.chageStepIndexTpPerv}},[_vm._v("返回")])])],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"pageContent"},[_c('div',{staticClass:"presentsOuter"},[_c('div',{staticClass:"presents pageBindSuccess STLiBian"},[_vm._v(" 已领取 ")])])])
}]

export { render, staticRenderFns }