var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container"},[([6, '已销卡', '已激活快递'].includes(_vm.currentStepIndex))?[_c('div',{staticClass:"itemContainer"},[_c('div',{class:{
        circle: true,
        step6: true,
        isActived: true
    }},[_vm._v("卡信息"+_vm._s(_vm.currentStepIndex))])])]:(_vm.isActivated)?[_c('div',{staticClass:"itemContainer"},[_c('div',{class:{
        circle: true,
        step1: true,
        isActived: true
    }},[_vm._v("卡信息")])])]:_vm._l((_vm.steps),function(item,index){return _c('div',{key:item,staticClass:"itemContainer"},[(index > 0)?_c('div',{class:{
        circle: true,
        step1: index === 1,
        step2: index === 2,
        step3: index === 3,
        isActived: index <= _vm.currentStepIndex
    }},[_vm._v(_vm._s(item))]):_vm._e(),(index > 0 && index < 3)?_c('div',{staticClass:"triangleOuter"},[_c('div',{class:{
        triangle: true,
        isActived: index <= _vm.currentStepIndex
    }})]):_vm._e()])})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }